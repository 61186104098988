<template>
  <div class="jumbotron">
    <div class="fhg-wrapper fhg-content fhg-richtext">
      <h1>Data Protection Policy</h1>
      When using this website, your experiment data and results will be
      processed by us as the data controller and stored for as long as is
      necessary for the fulfilment of the purposes specified and statutory
      requirements. Below, we will provide information about which data this
      involves, how it will be processed and to which rights you are entitled to
      in this regard. Only results such as if and when the input class (as
      explained in tutorial) is answered correctly of which experiment is
      stored.

      <p>&nbsp;</p>

      we kindly ask you to refrain from using your real name when signing up as
      a new user for the experiment. Therefore no Personal data, as defined by
      Article 4(1) General Data Protection Regulation (GDPR) includes any
      information relating to an identified or identifiable natural person will
      be stored for the experiment.

      <p>&nbsp;</p>

      <h4>
        <a> </a>- Name and contact data of the data controller and of the
        corporate Data Protection Officer
      </h4>
      <p>
        This data protection information is applicable to data processing on the
        <a
          href="https://www.fraunhofer.de/en.html"
          target="_blank"
          class="external"
          >www.fraunhofer.de</a
        >
        website by the controller:<br />
        <br />
        Fraunhofer-Gesellschaft zur Förderung der angewandten Forschung e.V.<br />
        Hansastraße 27c, 80686 München, Germany<br />
        Telephone +49 89 1205-0<br />
        Fax +49 89 1205-7531<br />
        info@zv.fraunhofer.de<br />
        www.fraunhofer.de
      </p>

      <p>
        <br />
        (hereinafter referred to as “Fraunhofer”)<br />
        <br />
        The Data Protection Officer at Fraunhofer may be reached at the
        above-mentioned address, attention of Data Protection Officer or at
        <a
          href="mailto:datenschutz@zv.fraunhofer.de"
          target="_blank"
          class="external"
          >datenschutz@zv.fraunhofer.de</a
        >.
      </p>

      <p>
        In case you have any questions about data protection law or your rights
        as the data subject, you may directly contact our Data Protection
        Officer.<br />
        &nbsp;
      </p>
    </div>
  </div>
</template>

<style scoped>
.fhg-wrapper {
  max-width: 1440px;
  margin: 0 auto;
  padding-left: 8rem;
  padding-right: 8rem;
  overflow: hidden;
  position: relative;
}

.fhg-content {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  line-height: 0;
}
.fhg-richtext {
  font-size: 1.4rem;
  line-height: 1.8rem;
}
</style>
