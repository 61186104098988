<template>
  <div class="jumbotron">
    <div class="offset-md-3 col-md-6">
      <p>&nbsp;</p>
      <h1 class="text-danger">Our experiment</h1>
      <p>&nbsp;</p>

      After loggin in, an image will be shown in the middle of the page (most of
      it is hidden), more of the image will be uncovered gradually.
      <p>&nbsp;</p>

      <img src="@/assets/s1.png" class="fit-image" />
      <p>&nbsp;</p>

      Once you recognise the class of the image, you can start typing in the
      textbox below the image. a list of suggestion will be shown, and you can
      select one of them using the keyboard or the mouse. If it's the <strong class="text-success">correct class</strong>, the entire image will be
      uncovered with a small message confirming your answer, otherwise if it's the <strong class="text-secondary">wrong class</strong> the input field will be cleared and you can try another class.
      <p>&nbsp;</p>

      <img src="@/assets/s2.png" class="fit-image45" />
      <img src="@/assets/s3.png" class="fit-image45" />
      <p>&nbsp;</p>

      Hint; to quicly correct the answer, click the "clear" button ;)
      <p>&nbsp;</p>

      After a minute or so, if the image is not recognised, the entire image
      will be uncovered, with a message stating the correct class. The
      uncovering of the image is based on some explation method that deemed the
      parts shown as most important for some image classifier model's
      prediction. Starting with most to least important parts.
      <p>&nbsp;</p>
      A short summary to the methods is provided below in Background.

      <h2>possible classes</h2>
      There are only the following 17 possible classes in this experiment;
      <ul class="columns" data-columns="2">
        <li>airliner (airplane) / Verkehrsflugzeug</li>
        <li>bald eagle, American eagle / Adler</li>
        <li>cassette player / Kassettenspieler</li>
        <li>dog; border collie / Hund</li>
        <li>dog; husky (Eskimo dog) / Hund</li>
        <li>ferret (polecat,fitch) / Frettchen</li>
        <li>great white shark / Weißer Hai</li>
                <li>horse sorrel / Pferd</li>
        <li>missile, rocket, or anti aircraft system / Raketen</li>
        <li>night snake / Schlange</li>
        <li>school bus / Schulbus</li>
        <li>screen / Bildschirm</li>
        <li>speedboat / schnelles Motorboot</li>
        <li>sports car / Sportwagen - Auto</li>
        <li>sunglasses / Sonnenbrille</li>
        <li>trailer truck (tractor , semi) / LKW, sattelzug, Traktor</li>
        <li>water jug / Wasserkrug</li>
      </ul>
      you can type in the class name in English or in German,
       a list of possible classes can be shown for either language on the right side of the experiment page.
      <p>&nbsp;</p>

      <h2>Data collected in this experiment</h2>
      the following data will be saved during the experiment:
      <ul>
        <li>the user ID number</li>
        <li>the image experiment number</li>
        <li>the percentage of the uncovered image shown</li>
        <li>
          the number of tries, where a suggestion is picked from the suggestion list
          untill the correct answer or times run out.
        </li>
      </ul>

      <h1 class="text-danger">Background</h1>
      In the last few years, explaining machine learning models (X-ML or X-AI)
      field has gain lots of momentum. Many methods where suggested, in this
      experiment we aim to evalute the quality of some of those methods for a
      human user, in compare to a baseline that is generated by human.
      <p>&nbsp;</p>
      We are testing the following methods:
      <ul>
        <li>
          <b>ClickMe:</b> are importance maps generated by one user clicks, so
          that another user or ML model can still guess the class of the
          original image. The maps and experiments condected by
          <a href="https://arxiv.org/abs/1805.08819">[Linsley Et al.]</a>.
        </li>
        <li>
          <b>Layer-wise Relevance Propagation (LRP):</b> is a method that
          identifies important pixels by running a backward pass in the neural
          network (going back from the decision step for a step, layer for a
          layer of the given model). more papers and exampls are provided
          <a href="http://www.heatmapping.org/">at this website</a>.
        </li>
        <li>
          <b>Occlusion:</b> is a method that simply hide part of the image and
          observe how the model decision change.
        </li>
                <li>
          <b>ProtoPNet:</b> is a new type of more interpretable model that combine 
          deep learning with ideas from traditional image recognition methods such as bag-of-visual-words. 
          ProtoPNet break up the image to its prototypical parts and then use those prototypes to predict the class.
          <a href="https://arxiv.org/abs/1805.08819">[C. Chen Et al.]</a>.
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.fit-image {
  height: 100%;
  width: 100%;
}

.fit-image45 {
  height: 48%;
  width: 48%;
}
</style>
